/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-07 15:48:31 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-07 15:48:31 
 */
<template>
  <div class="answerAnalysis">
    <div class="subject" v-for="(li, i) in 3" :key="i">
      <div class="subject_title">题目1：以下关于会计核算表达错误的是( )</div>
      <ul>
        <li>A:会计的基本职能是会计核算。</li>
        <li>B:会计的基本职能是会计核算。</li>
        <li>C:会计的基本职能是会计核算。</li>
        <li>D:会计的基本职能是会计核算。</li>
      </ul>
      <div class="da">
        <p>
          参考答案：
          <span class="c_1288F4">A</span>
        </p>
        <p>
          您的答案：
          <span class="c_EE1919">C</span>
          <i class="el-icon-close c_EE1919"></i>
        </p>
        <p>得分：0分</p>
      </div>
      <div class="jx">
        <p class="c_1288F4">答案解析:</p>
        <p>
          这里是答案解析这里是答案解析这里是答案解析这里是答案解析这里是答案解析这里是答案解析这里是答案解析
        </p>
      </div>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.answerAnalysis {
  .subject {
    border-bottom: 1px solid #f0eeee;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .subject_title {
      line-height: 100%;
      margin: 10px 0 20px;
    }
    ul {
      li {
        line-height: 25px;
        font-size: 14px;
        margin-bottom: 10px;
      }
      margin-bottom: 20px;
    }
    .da {
      height: 50px;
      line-height: 50px;
      background-color: #f3f3f3;
      padding: 0 20px;
      p {
        display: inline-block;
        margin-right: 40px;
        i {
          margin-left: 30px;
          font-size: 22px;
          vertical-align: middle;
        }
      }
    }
    .jx {
      p {
        &:first-child {
          font-size: 18px;
          line-height: 100%;
          margin: 20px 0;
        }
      }
    }
  }
}
</style>